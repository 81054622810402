import WelcomePage from '../views/WelcomePage.vue'
import ThirdPage from '@/components/ThirdPage.vue';
import ReviewPage from '@/components/ReviewPage.vue'
import { createRouter, createWebHistory } from 'vue-router';
import FirstPage from '@/components/FirstPage.vue';
import SecondPage from '@/components/SecondPage.vue';
import ConfirmationPage from '@/components/ConfirmationPage.vue';

const routes = [
  {
    path: "/",
    name: "welcome",
    component: WelcomePage,
  },
  {
    path: '/datenschutz',
    redirect: '/assets/datenschutz.pdf' 
  },
  {
    path: "/firstpage",
    name: "FirstPage",
    component: FirstPage,
  },
  {
    path: "/secondpage",
    name: "SecondPage",
    component: SecondPage,
  },
  {
    path: "/thirdpage",
    name: "ThirdPage",
    component: ThirdPage,
  },
  {
    path: "/reviewpage",
    name: "reviewpage",
    component: ReviewPage,
  },
  {
    path: "/confirmation",
    name: "Confirmation",
    component: ConfirmationPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
