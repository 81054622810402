<template>
    <div :class="'language-buttons'">
      <button :class="'language-button'" @click="switchToEnglish"> 🇺🇸  </button>
      <button :class="'language-button'" @click="switchToGerman"> 🇩🇪  </button>
    </div>
  </template>
  
  <script setup>
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  
  const store = useStore();
  const { locale } = useI18n();
  
  const switchLanguage = (language) => {
    store.dispatch('switchLanguage', language);
    locale.value = language;
  };
  
  const switchToEnglish = () => {
    switchLanguage('en');
  };
  
  const switchToGerman = () => {
    switchLanguage('de');
  };
  </script>
  <style scoped>
  .language-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px; 
  }
  
  .language-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px; 
    transition: transform 0.2s ease; 
    padding: 5px;
    border-radius: 50%; 
  }
  
  .language-button:hover {
    transform: translateY(-3px); 
  
  }
  </style>
  