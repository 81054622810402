export const validateEmail = (email: string) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }
  
  export const validatePhone = (phone: string) => {
    const pattern = /^(\+|0)[0-9]\d{4,15}$/ ;
    return pattern.test(phone);
  }
  
  export const validateName = (Name: string) => {
    const pattern = /^[A-Za-zäöüßÄÖÜ ]+$/;
    return pattern.test(Name);
  }
  
  export const validateZip = (zip: string) => {
    const pattern = /^[0-9]{5}$/;
    return pattern.test(zip);
  }
  export const validateAddress = (address: string) => {
    const pattern = /^[A-Za-zäöüßÄÖÜ\s-. / ]+$/;
    return pattern.test(address);
  }
  export const validatehouseNumber = (houseNumber: string) => {
    const pattern = /^[0-9a-z / ]+$/;
    return pattern.test(houseNumber);
  }
  export const validateState = (state: string) => {
   
    const statePattern = /^(Bad|BAY|BER|BRA|BRE|HAM|HES|MEC|NIE|NRW|RLP|SAA|SAC|SAI|SCH|THÜ)$/;
  
  
    return statePattern.test(state);
  };
  