import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createI18n } from 'vue-i18n';




const i18n = createI18n({
 legacy : false,

  locale: store.state.language,
  fallbackLocale: 'en',
  messages: {
    en: require('./lang/en.json'),
    de: require('./lang/de.json'),
  },
});

const app = createApp(App);

  
app.use(store);
app.use(router);
app.use(i18n);
app.mount('#app');