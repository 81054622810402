import { Commit, createStore } from 'vuex';
import axios from 'axios';


interface FormData {
  company: string;
  firstName: string;
  lastName: string;
  street: string;
  houseNumber: string;
  birthDate: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  email: string;
  totalTaxiCount: string;
  roofAds: boolean;
  digitalRoofAds: boolean;
  sideAds: boolean;
  interiorAds: boolean;
  centralName: string;
  centralNumber: string;
  alreadyHaveAds: string;
  duration: string;
  customer: string;
  central: string;
}

export interface TouchedFields {
  company: boolean;
  firstName: boolean;
  lastName: boolean;
  street: boolean;
  houseNumber: boolean;
  birthDate: boolean;
  city: boolean;
  state: boolean;
  zip: boolean;
  phone: boolean;
  email: boolean;
 
}

interface RootState {
  formData: FormData;
  privacyAccepted: boolean;
  language:string;
  touchedFields:TouchedFields;
  submitSuccess: boolean;       
  submitError: string | null; 
}

export default createStore<RootState>({
  state: {
    formData: {
      company: '',
      firstName: '',
      lastName: '',
      street: '',
      houseNumber: '',
      birthDate: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      email: '',
      totalTaxiCount: '',
      roofAds: false,
      digitalRoofAds: false,
      sideAds: false,
      interiorAds: false,
      central: '',
      centralName: '',
      centralNumber: '',
      alreadyHaveAds: '',
      duration: '',
      customer: '',
  
    },
    privacyAccepted: false,
    language:'de',
    submitSuccess: false,  
    submitError: null, 

    touchedFields :{
      company: true,
      firstName: true,
      lastName: true,
      street: true,
      houseNumber: true,
      birthDate:true,
      city: true,
      state:true,
      zip: true,
      phone: true,
      email:true,
     
    }
  },
  getters: {
    getFormData: (state) => state.formData,
    getPrivacyAccepted: (state) => state.privacyAccepted,
    getTouchedFields: (state) => state.touchedFields,
    getSubmitSuccess: (state) => state.submitSuccess,
    getSubmitError: (state) => state.submitError,
  },
  mutations: {
    setFormData: (state, formData) => {
      state.formData = { ...state.formData, ...formData } as FormData;
    },
    setPrivacyAccepted: (state, value) => { 
      state.privacyAccepted = value;
  },
  setLanguage(state, language) {
    state.language = language;
  },
  
  setFieldTouched(state: RootState, { field, value }: { field: keyof TouchedFields; value: boolean }) {
    state.touchedFields[field] = value;
  },
  setSubmitSuccess: (state, value) => {
    state.submitSuccess = value;
  },
  setSubmitError: (state, error) => {
    state.submitError = error;
  },
},

  actions: {

    

    nextStep({ commit, state }, formData) {
      commit('setFormData', formData);
      console.log(state.formData);
    },
    updatePrivacyAccepted({ commit }, value) { 
      commit('setPrivacyAccepted', value);
    },
   
    async submitForm({ commit, state }) {
      try {
        console.log(state.formData);

        const apiUrl = process.env.VUE_APP_API;
        console.log("API URL:", apiUrl);
    const response = await axios.post(apiUrl, state.formData);
    console.log("API URL:", apiUrl);
        console.log(response.data);
        commit('setSubmitSuccess', true);      
        commit('setSubmitError', null); 
      } catch (error) {
        
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.data && error.response.data.message) {
              commit('setSubmitError', error.response.data.message);
          } else {
              commit('setSubmitError', true);
          }
      }
    }
  },

    switchLanguage({ commit }, language) {
       
      if (language === 'en' || language === 'de') {
        commit('setLanguage', language); 
    
      } else {
        console.warn('Invalid language value:', language);
      }
    },
    async updateFieldTouched(
      { commit }: { commit: Commit; state: RootState },
      { field, value }: { field: keyof TouchedFields; value: boolean }
    ) {
      commit('setFieldTouched', { field, value });
    }
    
  },
  modules: {},
});
